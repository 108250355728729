import React from 'react';

const View = () => {

  return (
    <>
      <h1>About</h1>
    </>
  );
};

export default View;
